// src/App.js
import {Route, Routes} from 'react-router-dom';

import {useSelector} from "react-redux";
import {useAuth} from "./hooks/useAuth";

//-LAYOUTS
import Header from "./views/layouts/Header";
import Footer from "./views/layouts/Footer";
import ToastContainer from "./views/layouts/ToastContainer";
//-SITES
//--STATIC
import Home from './views/pages/Home/Home';
import Login from './views/pages/Login/Login';
import Logout from "./views/pages/Logout/Logout";
import SiteNotFound from "./views/pages/SiteNotFound/SiteNotFound";

import Imprint from "./views/pages/Imprint/Imprint";
import PrivacyPolicy from "./views/pages/PrivacyPolicy/PrivacyPolicy";
//--DASHBOARD
import Dashboard from './views/pages/Dashboard/Dashboard';
import DashboardProfile from "./views/pages/Dashboard/Profile/Profile";
//---ADMIN
import DashboardAdminUser from './views/pages/Dashboard/Admin/User/User';

function App() {
    const user = useSelector((state) => state.user);
    useAuth();

    return (
        <>
            <ToastContainer />
            <Header/>
            <div id={"rootApp"}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/login" element={!user ? <Login/> : <Dashboard/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/imprint" element={<Imprint/>}/>
                    <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
                    {user ? (<>
                    <Route path="/dashboard" element={user ? <Dashboard/> : <Login/>}>
                        <Route path="profile" element={<DashboardProfile/>}/>
                        {(user?.role === 'admin' || user?.role === 'root') && (
                            <Route path="admin/user" element={<DashboardAdminUser/>}/>
                        )}
                    </Route>
                        </>
                    ) : null}
                    <Route path="*" element={<SiteNotFound/>}/>
                </Routes>
            </div>
            <Footer/>
        </>
    );
}

export default App;
