import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../middleware/axiosInstance';

// Async thunk to fetch settings from the server after login
export const fetchUserSettings = createAsyncThunk(
    'settings/fetchUserSettings',
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            const { user } = getState();  // Get the user state
            if (user) {
                // Fetch settings from the server
                const response = await axiosInstance.get('/me/settings');
                const serverSettings = response.data;

                // Merge local and server settings
                dispatch(mergeSettings(serverSettings));
                return serverSettings;
            }
            return null;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch settings');
        }
    }
);

// Async thunk to update settings both locally and in the backend (if logged in)
export const updateSettingsAPI = createAsyncThunk(
    'settings/updateSettingsAPI',
    async (newSettings, { dispatch, getState, rejectWithValue }) => {
        try {
            // Update local settings immediately
            dispatch(updateSettings(newSettings));

            // Get the user from the state
            const { user } = getState();

            // If the user is logged in, save the settings to the backend
            if (user) {
                await axiosInstance.put('/me', { settings: newSettings });
            }

            return true;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to update settings');
        }
    }
);

// Load initial state from localStorage or set default values
const loadInitialState = () => {
    const initialState = {
        "uuid": 1,
        "status": "local",
        "isLoggedIn": false,
        "isCookieConsent": false,
        "newsletter": false,
        "theme": "light-mode"
    };
    try {
        const localSettings = localStorage.getItem('settings');
        return localSettings? JSON.parse(localSettings) : initialState;
    } catch (error) {
        return initialState;  // Default local settings
    }
};

const initialState = loadInitialState();

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        resetSettings(state) {
            localStorage.removeItem('settings');
            return { ...initialState };  // Reset settings to initial state
        },
        setLocalSetting(state, action) {
            const { key, value } = action.payload;
            state[key] = value;

            // Update localStorage for local settings
            localStorage.setItem('settings', JSON.stringify(state));
        },
        mergeSettings(state, action) {
            // Merge server-side settings with local settings
            const serverSettings = action.payload;
            const mergedSettings = { ...state, ...serverSettings };

            // Save the merged settings back to localStorage
            localStorage.setItem('settings', JSON.stringify(mergedSettings));

            return mergedSettings;  // Server settings overwrite local settings
        },
        updateSettings(state, action) {
            // Update settings and store them in localStorage
            const updatedSettings = { ...state, ...action.payload };
            localStorage.setItem('settings', JSON.stringify(updatedSettings));

            return updatedSettings;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserSettings.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(fetchUserSettings.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateSettingsAPI.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateSettingsAPI.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(updateSettingsAPI.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

// Export actions
export const { resetSettings, setLocalSetting, mergeSettings, updateSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
