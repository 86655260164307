import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {removeToast} from '../../store/toastSlice';

import logoDark from "../../images/themes/dark/logo.png"
import logoLight from "../../images/themes/light/logo.png"

const ToastContainer = () => {
    const toasts = useSelector(state => state.toast.toasts);

    return (
        <div id="toastContainer">
            {toasts.map((toast) => (
                <Toast key={toast.id} toast={toast}/>
            ))}
        </div>
    );
};

const Toast = ({toast}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const settings = useSelector(state => state.settings);

    useEffect(() => {
        // Set a timeout for the individual toast
        const timer = setTimeout(() => {
            dispatch(removeToast(toast.id));
        }, 3000);

        // Cleanup the timer if the component unmounts before the timer completes
        return () => clearTimeout(timer);
    }, [dispatch, toast.id]);

    return (
        <div className={`toast toast-${toast.type}`}>
            <div className="toast-header">
                <img
                    src={settings.theme === "light-mode" && `${logoLight}` || `${logoDark}`}
                    className="rounded me-2" alt="logo"/>
                <strong className="me-auto">{process.env.REACT_APP_NAME}</strong>
            </div>
            <div className="toast-body">
                <div dangerouslySetInnerHTML={{__html: toast.message}}/>
            </div>
            <div className="toast-footer">
                {user && (
                    <div>
                        {user.displayName}
                    </div>
                )}
                <div>
                    {new Date().toLocaleString()} {/* This will display the current date and time */}
                </div>
            </div>

        </div>
    );
};

export default ToastContainer;
