import React from 'react'
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <p>
                {process.env.REACT_APP_NAME}
            </p>
            <p className={"d-flex flex-row gap-2"}>
                <Link to={"/imprint"}>Imprint</Link>
                <Link to={"/privacypolicy"}>Privacy Policy</Link>
            </p>
        </footer>
    )
}
