import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const SiteNotFound = () => {
    const user = useSelector((state) => state.user);

    return (
        <div id={"SiteNotFound"}>
            {
                user === undefined &&
                <div>
                    Hey, die Seite wurde nicht gefunden. <br/>
                    Wenn du zurück möchtest, klicke einfach <Link
                    to={"/"}>hier</Link>!
                </div> ||
                <div>
                    Hey {user.username}, hast du dich verlaufen? <br />
                    Zurück zur <Link to={"/"}>Homepage</Link> oder zum <Link to={"/dashboard"}>Dashboard</Link>?
                </div>
            }
        </div>
    );
};

export default SiteNotFound;
