// src/pages/Profile.js
import React, { useEffect, useState } from 'react';
import axiosInstance from "../../../../middleware/axiosInstance";
import DynamicModalForm from '../../../components/DynamicModalForm';
import {useSelector, useDispatch} from "react-redux";
import {setUser} from "../../../../store/userSlice";
import {addToast} from "../../../../store/toastSlice";

const DashboardProfile = () => {
    const user = useSelector((state) => state.user);
    const [showModal, setShowModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(user || {});
    const [isProfileUpdateLoading, setIsProfileUpdateLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!user) {
            axiosInstance.get(`/me`).then((data) => {
                setCurrentUser(data.user);
            });
        } else {
            setCurrentUser(user);
        }
    }, []);

    const profileFields = [
        { name: 'username', label: 'Username', type: 'text', required: true },
        { name: 'email', label: 'Email', type: 'email' },
        { name: 'telephone', label: 'Telephone', type: 'text' },
        { name: 'profilePicture', label: 'Profile Picture URL', type: 'text' },
        { name: 'bio', label: 'Bio', type: 'textarea', rows: 4 },
        { name: 'websiteUrl', label: 'Website URL', type: 'text' },
    ];

    const handleEdit = () => {
        setShowModal(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isProfileUpdateLoading) {
            setIsProfileUpdateLoading(true);
            try {
                await axiosInstance.put(`/me`, {
                    user: currentUser
                });
                setShowModal(false);
                setIsProfileUpdateLoading(false);
                const response = await axiosInstance.get(`/me`);
                setUser(response.data.user); // Refresh the user data after update
            } catch (error) {
                dispatch(addToast({id: Date.now(), message: 'Error in Twitch Sync, try again later.', type: 'error'}));
                console.error('Error updating profile:', error);
            }
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    return (
        <div id="DashboardProfile">
            <div>
                <h1>Mein Profil</h1>
                <div>
                    <p><strong>Benutzername:</strong> {user.username}</p>
                    <p><strong>Email:</strong> {user.email}</p>
                    <p><strong>Telefonnummer:</strong> {user.telephone || 'Keine Angabe'}</p>
                    <p><strong>Website:</strong> <a href={user.websiteUrl} target="_blank" rel="noopener noreferrer">{user.websiteUrl}</a></p>
                    <p><strong>Profilbild:</strong> {user.profilePicture ? <img src={user.profilePicture} alt="Profilbild" className="profileImage" /> : 'Keine Angabe'}</p>
                    <p><strong>Biografie:</strong> {user.bio || 'Keine Angabe'}</p>
                    <p><strong>Rolle:</strong> {user.role}</p>
                    <p><strong>Erstellt am:</strong> {new Date(user.createdAt).toLocaleString()}</p>
                    <p><strong>Zuletzt aktualisiert:</strong> {new Date(user.updatedAt).toLocaleString()}</p>
                    <p><strong>Letzte Anmeldung:</strong> {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : 'Noch keine Anmeldung'}</p>
                </div>
                <button onClick={handleEdit}>Profil verwalten</button>
                {
                    isProfileUpdateLoading && <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
            </div>
            <DynamicModalForm
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                entity={currentUser}
                title="Profil bearbeiten"
                fields={profileFields}
                isEdit={true}
            />
        </div>
    );
};

export default DashboardProfile;
