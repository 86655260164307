import React from 'react'

export default function PrivacyPolicy() {
    return (
        <div id={"PrivacyPolicy"} className={"floatingContainer"}>
            <h1>Datenschutzerklärung</h1>
            <div className="section">
                <h2>1. Verantwortliche Stelle</h2>
                <p>
                    [Business Name]<br/>
                    [Street Address]<br/>
                    [ZIP Code, City]<br/>
                    Deutschland<br/>
                    Telefon: [Phone Number]<br/>
                    E-Mail: [Email Address]
                </p>
            </div>

            <div className="section">
                <h2>2. Datenerfassung und Nutzung</h2>
                <p>
                    Wir erfassen und verarbeiten personenbezogene Daten nur im erforderlichen Umfang für den
                    Betrieb unserer Webanwendung. Dazu gehören Session-Tokens zur Authentifizierung und
                    Verwaltung von Benutzer-Sitzungen.
                </p>
                <p>Gesammelte Daten:</p>
                <ul>
                    <li>Session-Tokens</li>
                    <li>IP-Adresse</li>
                </ul>
            </div>

            <div className="section">
                <h2>3. Rechtsgrundlage</h2>
                <p>
                    Die Verarbeitung personenbezogener Daten erfolgt gemäß Art. 6 Abs. 1 lit. b DSGVO zur
                    Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen und Art. 6 Abs. 1 lit. f DSGVO
                    zur Wahrung unserer berechtigten Interessen.
                </p>
            </div>

            <div className="section">
                <h2>4. Speicherungsdauer</h2>
                <p>Session-Tokens werden nur für die Dauer der Benutzersitzung gespeichert und nach dem
                    Ausloggen gelöscht.</p>
            </div>

            <div className="section">
                <h2>5. Weitergabe von Daten</h2>
                <p>Wir geben personenbezogene Daten nicht an Dritte weiter, es sei denn, es besteht eine
                    gesetzliche Verpflichtung oder dies ist für den Betrieb der Webanwendung notwendig.</p>
            </div>

            <div className="section">
                <h2>6. Rechte der Nutzer</h2>
                <p>
                    Benutzer haben folgende Rechte in Bezug auf ihre personenbezogenen Daten:
                    <ul>
                        <li>Auskunft (Art. 15 DSGVO)</li>
                        <li>Berichtigung (Art. 16 DSGVO)</li>
                        <li>Löschung (Art. 17 DSGVO)</li>
                        <li>Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
                        <li>Datenübertragbarkeit (Art. 20 DSGVO)</li>
                        <li>Widerspruch gegen die Verarbeitung (Art. 21 DSGVO)</li>
                    </ul>
                </p>
                <p>Um diese Rechte auszuüben, kontaktieren Sie uns unter [Email Address].</p>
            </div>

            <div className="section">
                <h2>7. Sicherheit</h2>
                <p>Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten vor
                    unberechtigtem Zugriff und Missbrauch zu schützen.</p>
            </div>

            <div className="section">
                <h2>8. Kontakt für Datenschutzanfragen</h2>
                <p>
                    Bei Fragen zum Datenschutz wenden Sie sich bitte an unseren
                    Datenschutzbeauftragten:<br/>
                    [Name]<br/>
                    E-Mail: [Email Address]
                </p>
            </div>
        </div>
    )
}
