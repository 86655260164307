import React from 'react'

export default function Imprint() {
    return (
        <div id={"Imprint"} className={"floatingContainer"}>
            <h1>Impressum</h1>

            <div className="section">
                <h2>1. Anbieterinformationen</h2>
                <p>
                    [Business Name]<br/>
                    [Street Address]<br/>
                    [ZIP Code, City]<br/>
                    Deutschland<br/>
                    Telefon: [Phone Number]<br/>
                    E-Mail: [Email Address]<br/>
                    Website: <a href="[Your Website URL]">[Your Website URL]</a>
                </p>
            </div>

            <div className="section">
                <h2>2. Rechtsform und USt-ID</h2>
                <p>Rechtsform: [z.B., Einzelunternehmen, GmbH]<br/>
                    USt-ID: [Umsatzsteuer-Identifikationsnummer, falls vorhanden]<br/>
                    Handelsregister: [Falls zutreffend]<br/>
                    Handelsregisternummer: [Falls zutreffend]</p>
            </div>

            <div className="section">
                <h2>3. Verantwortlich für den Inhalt gemäß § 55 Abs. 2 RStV</h2>
                <p>
                    [Name]<br/>
                    [Street Address]<br/>
                    [ZIP Code, City]
                </p>
            </div>

            <div className="section">
                <h2>4. Haftungsausschluss</h2>
                <p>
                    Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer
                    Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                </p>
            </div>
        </div>
    )
}
