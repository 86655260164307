// src/pages/Login.js
import React, { useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const handleSubmit = async (e) => {
        e.preventDefault();
        await login(username, password);
    };

    return (
        <div id={"Login"} className={"floatingContainer d-flex flex-column justify-content-center align-items-center"}>
            <form onSubmit={handleSubmit}
                  className={"d-flex flex-column justify-content-center align-items-center w-50"}>
                <label htmlFor="username" className={"align-self-start"}>Username:</label>
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                       placeholder="Username" className={"standardInput w-100"}/>
                <label htmlFor="username" className={"align-self-start"}>Password:</label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                       placeholder="Password" className={"standardInput w-100"}/>
                <button type="submit" className={"btn btn-outline-success"}>Login</button>
            </form>
        </div>
    );
};

export default Login;
